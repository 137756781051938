<template>
  <div class="all-book-topic-items">
    <v-card v-if="topic && topic.is_draft" class="mb-2" color="primary lighten-5">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-if="topic.scheduled">
            This topic is scheduled for <strong class="orange--text">{{ moment(topic.scheduled).format('LLL') }}</strong>
          </v-list-item-title>
          <v-list-item-title class="text-wrap body-2" v-else
            >This topic is marked as <strong>'Draft'</strong></v-list-item-title
          >
        </v-list-item-content>

        <v-list-item-icon v-if="canManage"
          ><v-btn @click="showPublishDialog = true" small color="primary"
            ><v-icon class="mr-2">mdi-publish</v-icon> Publish <span v-if="topic.scheduled"> Now</span></v-btn
          ></v-list-item-icon
        >
      </v-list-item>
    </v-card>
    <v-row justify="center">
      <v-col md="6">
        <!-- <v-list class="pt-0" v-if="items.length">
          <template v-for="item in items">
            <div :key="item.id">
              <v-list-item @click="handleClick(item)">
                <v-list-item-content>
                  <v-list-item-title class="text-wrap font-weight-medium"
                    >{{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-1">
                    <v-chip small color="green lighten-4" v-if="item.type == 1"
                      >Introduction</v-chip
                    >
                    <v-chip
                      small
                      color="green lighten-4"
                      v-else-if="item.type == 2"
                      >Assignment</v-chip
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon
                  v-if="item.is_draft"
                  class="align-self-center"
                >
                  <v-chip small>Draft</v-chip>
                </v-list-item-icon>
              </v-list-item>
              <v-divider class="mx-4"></v-divider>
            </div>
          </template>
        </v-list> -->

        <intract-smart-list
          ref="topic-items-smart-list"
          v-if="topicItemsEndpoint"
          :endpoint="topicItemsEndpoint"
        >
          <template v-slot:list-item="{ item: item }">
              <v-list-item @click="handleClick(item)">
                <v-list-item-content>
                  <v-list-item-title class="text-wrap font-weight-medium"
                    >{{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-1">
                    <v-chip small outlined color="primary" v-if="item.type == 1"
                      >{{ item.is_teacher_reference? 'Teacher Reference' : 'Introduction'}}</v-chip
                    >
                    <v-chip
                      small
                      color="primary"
                      outlined
                      v-else-if="item.type == 2"
                      >Assignment</v-chip
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon
                  v-if="item.is_draft"
                  class="align-self-center"
                >
                  <v-chip small color="primary" outlined>Draft</v-chip>
                </v-list-item-icon>
              </v-list-item>
              <v-divider class="mx-4"></v-divider> 


          </template>
        </intract-smart-list>

        <div v-else class="text-center mt-4"><h3>No items found</h3></div>
      </v-col>
    </v-row>
    <confirmation-dialog
      title="Are you sure you want to publish this topic?"
      description="This will notify all the students from this classroom that the topic has been published. Note that this will not publish internal items automatically."
      :visible="showPublishDialog"
      @successCallback="publishTopic"
      @failureCallback="showPublishDialog = false"
    />
    <v-fab-transition>
      <v-btn
        color="primary"
        v-if="!isLoading && !currentUser.is_student"
        @click="showCreationOptions = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <intract-list-sheet
      title="What do you want to create?"
      :visible="showCreationOptions"
      :list="creationOptions"
      @close="showCreationOptions = false"
    />
    <intract-create-edit-sheet
      persistent
      key="introduction-create-edit-sheet"
      class="introduction-create-edit-sheet"
      :title="
        introSheet.editId
          ? 'Edit Topic Introduction'
          : 'Create Topic Introduction'
      "
      description="Introduce your students to a new topic"
      :visible="introSheet.visible"
      @close="
        introSheet.visible = false;
        introSheet.editId = null;
      "
      :fields="introFormFields"
      :file-size-limit="5242880"
      :endpoint="endpoints.topicIntroductions"
      :edit-id="introSheet.editId"
      :data-object="introSheet.obj"
      :create-success-message="introSheet.createSuccessMessage"
      enable-media
      @objectCreated="introductionCreated"
      @updateObject="(obj) => (introSheet.obj = obj)"
    />
    <intract-create-edit-sheet
      key="assignment-create-edit-sheet"
      class="assignment-create-edit-sheet"
      :title="
        assignmentSheet.editId
          ? 'Edit Topic Assignment'
          : 'Create Topic Assignment'
      "
      description="An assignment for students to better understand the topic"
      :visible="assignmentSheet.visible"
      @close="
        assignmentSheet.visible = false;
        assignmentSheet.editId = null;
      "
      :fields="assignmentFormFields"
      :endpoint="endpoints.topicAssignments"
      :edit-id="assignmentSheet.editId"
      :data-object="assignmentSheet.obj"
      :create-success-message="assignmentSheet.createSuccessMessage"
      enable-media
      @objectCreated="assignmentCreated"
      @updateObject="(obj) => (assignmentSheet.obj = obj)"
    />
    <intract-create-edit-sheet
      persistent
      key="teacher-reference-create-edit-sheet"
      class="teacher-reference-create-edit-sheet"
      :title="
        referenceSheet.editId
          ? 'Edit Teacher Reference'
          : 'Create Teacher Reference'
      "
      description="Special instructions, only visible to teachers"
      :visible="referenceSheet.visible"
      @close="
        referenceSheet.visible = false;
        referenceSheet.editId = null;
      "
      :fields="teacherReferenceFormFields"
      :file-size-limit="5242880"
      :endpoint="endpoints.topicIntroductions"
      :edit-id="referenceSheet.editId"
      :data-object="referenceSheet.obj"
      :create-success-message="referenceSheet.createSuccessMessage"
      enable-media
      @objectCreated="introductionCreated"
      @updateObject="(obj) => (referenceSheet.obj = obj)"
    />
  </div>
</template>
<script>

import IntractSmartList from "@components/generics/IntractSmartList";
import Mixins from "@utils/mixins";
import moment from 'moment';
import EventBus from "@utils/event_bus";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import IntractListSheet from "@components/generics/IntractListSheet";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
export default {
  name: "AllBookTopicItems",
  components: {
    IntractSmartList,
    ConfirmationDialog,
    IntractListSheet,
    IntractCreateEditSheet,
  },
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarTitle],
  props: {
    topicTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      routeName : this.$route.meta.is_activity ? 'ActivitySheet' : 'Book',
      topicId: this.$route.params.topicId,
      pr: null,
      appBarTitle: this.topicTitle,
      showPublishDialog: false,
      moment,
      showCreationOptions: false,
      creationOptions: [
        {
          icon: "mdi-play-protected-content",
          title: "Introduction",
          subtitle: "Introduce your students to a new topic",
          action: () => (this.introSheet.visible = true),
        },
        {
          icon: "mdi-book-open",
          title: "Assignment",
          subtitle: "An assignment for students to better understand the topic",
          action: () => (this.assignmentSheet.visible = true),
        },
        {
          icon: "mdi-human-male-board",
          title: "Teacher Reference",
          subtitle: "Special instructions, only visible to teachers",
          action: () => (this.referenceSheet.visible = true),
        },
      ],
      topic: null,
      introSheet: {
        visible: false,
        createSuccessMessage: "Topic Introduction created successfully!",
        editId: null,
        obj: {
          book_topic: this.$route.params.topicId,
          is_draft: false,
          creator: null,
        },
      },
      referenceSheet: {
        visible: false,
        createSuccessMessage: "Teacher Reference created successfully!",
        editId: null,
        obj: {
          book_topic: this.$route.params.topicId,
          is_draft: false,
          is_teacher_reference: true,
          creator: null,
        },
      },
      assignmentSheet: {
        visible: false,
        createSuccessMessage: "Topic Assignment created successfully!",
        editId: null,
        obj: {
          book_topic: this.$route.params.topicId,
          is_draft: false,
          creator: null,
        },
      },
      items: [],
    };
  },
  computed: {
    topicItemsEndpoint() {
      // this.items = []
      var url = this.endpoints.bookTopicItems + this.topicId + "/";
      // this.items = await this.api.call(this.essentials, url);
      return url;
    },

    canManage() {
      return this.currentUser.is_admin || this.currentUser.is_faculty;
    },

    teacherReferenceFormFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        is_draft: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Save as draft?",
          md: 12,
          helper:
            "Enabling this will save this topic as draft and will not be shown to your students",
          hide: true,
          value: false,
        },
        topic_intro_images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        },
        topic_intro_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
        topic_intro_videos: {
          fieldType: CreateEditFieldTypes.LINKUPLOAD,
          multiple: true,
          label: "Video Link",
          required: true,
        },
        is_teacher_reference: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Hidden Teacher Reference",
          md: 12,
          hide: true,
          value: true
        }
      };
    },

    introFormFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        is_draft: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Save as draft?",
          md: 12,
          helper:
            "Enabling this will save this topic as draft and will not be shown to your students",
        },
        topic_intro_images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        },
        topic_intro_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
        topic_intro_videos: {
          fieldType: CreateEditFieldTypes.LINKUPLOAD,
          multiple: true,
          label: "Video Link",
          required: true,
        },
      };
    },
    assignmentFormFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        is_draft: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Save as draft?",
          md: 12,
          helper:
            "Enabling this will save this topic as draft and will not be shown to your students",
        },
        topic_assignment_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },
  },
  methods: {
    async getTopicDetails() {
      this.topic = await this.api.call(
        this.essentials,
        this.endpoints.bookTopics + this.$route.params.topicId + "/"
      );
      this.appBarTitle = this.topic.title;
    },

    async handleClick(item) {
      if (item.type == 1)
        this.$router.push({
          name: this.routeName + "TopicIntroduction",
          params: { introId: item.id, introTitle: item.title },
        });
      else if (item.type == 2)
        this.$router.push({
          name: this.routeName + "TopicAssignment",
          params: { assignmentId: item.id },
        });
    },

    async publishTopic() {
      this.showPublishDialog = false;

      var url = this.endpoints.bookTopics + this.topic.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { is_draft: false }
      );
      if (response) {
        this.topic.is_draft = false;
        this.showSnackbar({
          title: "Topic has been published",
          text: "Students of this classroom will be notified",
          type: "success",
        });
      }
    },

    async introductionCreated(intro) {
      this.onRefresh();
      this.$router.push({
        name: this.routeName + "TopicIntroduction",
        params: { introId: intro.id, introTitle: intro.title },
      });
    },

    async assignmentCreated(assignment) {
      this.onRefresh();
      this.$router.push({
        name: this.routeName + "TopicAssignment",
        params: {
          assignmentId: assignment.id,
          assignmentTitle: assignment.title,
        },
      });
    },

    async onRefresh() {
      if (this.$refs["topic-items-smart-list"])
          this.$refs["topic-items-smart-list"].getItems();
    },

    setListeners() {
      EventBus.$on("topic_introduction__introduction_updated", (post) => {
        // var intro = this.items.find(
        //   (item) => item.type == 1 && item.id == i.id
        // );
        // if (!intro) return;
        // intro.is_draft = i.is_draft;
        // intro.title = i.title;
        if (this.$refs["topic-items-smart-list"])
          this.$refs["topic-items-smart-list"].replaceItem(post.id, post);
      });
      EventBus.$on(
        "topic_introduction__introduction_deleted",
        // (deletedId) =>
        //   (this.items = this.items.filter(
        //     (item) => !(item.type == 1 && item.id == deletedId)
        //   ))
        (id) => (this.$refs["topic-items-smart-list"].removeItem(id)),   
      );
      EventBus.$on("topic_assignment__assignment_updated", (post) => {
        // var assignment = this.items.find(
        //   (item) => item.type == 2 && item.id == i.id
        // );
        // if (!assignment) return;

        // assignment.is_draft = i.is_draft;
        // assignment.title = i.title;

        if (this.$refs["topic-items-smart-list"])
          this.$refs["topic-items-smart-list"].replaceItem(post.id, post);
      });
      EventBus.$on(
        "topic_assignment__assignment_deleted",
        // (deletedId) =>
        //   (this.items = this.items.filter(
        //     (item) => !(item.type == 2 && item.id == deletedId)
        //   ))
        (id) => (this.$refs["topic-items-smart-list"].removeItem(id)),   
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    if (![this.routeName + "TopicIntroduction", this.routeName + "TopicAssignment"].includes(to.name)) {
      EventBus.$emit("keep_alive__destroy_view", "AllBookTopicItems");
    }
    next();
  },

  async created() {
    this.onRefresh();
    this.getTopicDetails();
    this.introSheet.obj.creator = this.currentUser.id;
    this.assignmentSheet.obj.creator = this.currentUser.id;
    this.setListeners();

  },
};
</script>