var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-book-topic-items"},[(_vm.topic && _vm.topic.is_draft)?_c('v-card',{staticClass:"mb-2",attrs:{"color":"primary lighten-5"}},[_c('v-list-item',[_c('v-list-item-content',[(_vm.topic.scheduled)?_c('v-list-item-title',[_vm._v(" This topic is scheduled for "),_c('strong',{staticClass:"orange--text"},[_vm._v(_vm._s(_vm.moment(_vm.topic.scheduled).format('LLL')))])]):_c('v-list-item-title',{staticClass:"text-wrap body-2"},[_vm._v("This topic is marked as "),_c('strong',[_vm._v("'Draft'")])])],1),(_vm.canManage)?_c('v-list-item-icon',[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.showPublishDialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-publish")]),_vm._v(" Publish "),(_vm.topic.scheduled)?_c('span',[_vm._v(" Now")]):_vm._e()],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[(_vm.topicItemsEndpoint)?_c('intract-smart-list',{ref:"topic-items-smart-list",attrs:{"endpoint":_vm.topicItemsEndpoint},scopedSlots:_vm._u([{key:"list-item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap font-weight-medium"},[_vm._v(_vm._s(item.title)+" ")]),_c('v-list-item-subtitle',{staticClass:"mt-1"},[(item.type == 1)?_c('v-chip',{attrs:{"small":"","outlined":"","color":"primary"}},[_vm._v(_vm._s(item.is_teacher_reference? 'Teacher Reference' : 'Introduction'))]):(item.type == 2)?_c('v-chip',{attrs:{"small":"","color":"primary","outlined":""}},[_vm._v("Assignment")]):_vm._e()],1)],1),(item.is_draft)?_c('v-list-item-icon',{staticClass:"align-self-center"},[_c('v-chip',{attrs:{"small":"","color":"primary","outlined":""}},[_vm._v("Draft")])],1):_vm._e()],1),_c('v-divider',{staticClass:"mx-4"})]}}],null,false,498894927)}):_c('div',{staticClass:"text-center mt-4"},[_c('h3',[_vm._v("No items found")])])],1)],1),_c('confirmation-dialog',{attrs:{"title":"Are you sure you want to publish this topic?","description":"This will notify all the students from this classroom that the topic has been published. Note that this will not publish internal items automatically.","visible":_vm.showPublishDialog},on:{"successCallback":_vm.publishTopic,"failureCallback":function($event){_vm.showPublishDialog = false}}}),_c('v-fab-transition',[(!_vm.isLoading && !_vm.currentUser.is_student)?_c('v-btn',{attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":function($event){_vm.showCreationOptions = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('intract-list-sheet',{attrs:{"title":"What do you want to create?","visible":_vm.showCreationOptions,"list":_vm.creationOptions},on:{"close":function($event){_vm.showCreationOptions = false}}}),_c('intract-create-edit-sheet',{key:"introduction-create-edit-sheet",staticClass:"introduction-create-edit-sheet",attrs:{"persistent":"","title":_vm.introSheet.editId
        ? 'Edit Topic Introduction'
        : 'Create Topic Introduction',"description":"Introduce your students to a new topic","visible":_vm.introSheet.visible,"fields":_vm.introFormFields,"file-size-limit":5242880,"endpoint":_vm.endpoints.topicIntroductions,"edit-id":_vm.introSheet.editId,"data-object":_vm.introSheet.obj,"create-success-message":_vm.introSheet.createSuccessMessage,"enable-media":""},on:{"close":function($event){_vm.introSheet.visible = false;
      _vm.introSheet.editId = null;},"objectCreated":_vm.introductionCreated,"updateObject":function (obj) { return (_vm.introSheet.obj = obj); }}}),_c('intract-create-edit-sheet',{key:"assignment-create-edit-sheet",staticClass:"assignment-create-edit-sheet",attrs:{"title":_vm.assignmentSheet.editId
        ? 'Edit Topic Assignment'
        : 'Create Topic Assignment',"description":"An assignment for students to better understand the topic","visible":_vm.assignmentSheet.visible,"fields":_vm.assignmentFormFields,"endpoint":_vm.endpoints.topicAssignments,"edit-id":_vm.assignmentSheet.editId,"data-object":_vm.assignmentSheet.obj,"create-success-message":_vm.assignmentSheet.createSuccessMessage,"enable-media":""},on:{"close":function($event){_vm.assignmentSheet.visible = false;
      _vm.assignmentSheet.editId = null;},"objectCreated":_vm.assignmentCreated,"updateObject":function (obj) { return (_vm.assignmentSheet.obj = obj); }}}),_c('intract-create-edit-sheet',{key:"teacher-reference-create-edit-sheet",staticClass:"teacher-reference-create-edit-sheet",attrs:{"persistent":"","title":_vm.referenceSheet.editId
        ? 'Edit Teacher Reference'
        : 'Create Teacher Reference',"description":"Special instructions, only visible to teachers","visible":_vm.referenceSheet.visible,"fields":_vm.teacherReferenceFormFields,"file-size-limit":5242880,"endpoint":_vm.endpoints.topicIntroductions,"edit-id":_vm.referenceSheet.editId,"data-object":_vm.referenceSheet.obj,"create-success-message":_vm.referenceSheet.createSuccessMessage,"enable-media":""},on:{"close":function($event){_vm.referenceSheet.visible = false;
      _vm.referenceSheet.editId = null;},"objectCreated":_vm.introductionCreated,"updateObject":function (obj) { return (_vm.referenceSheet.obj = obj); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }